.adminWrapper {
  margin-top: -20px;
  .bottomTabPanel {
    padding: 2rem 2rem 0rem 2rem;
    display: flex;
    flex-direction: column;
  }
  .tabTypography {
    text-transform: none;
    font-weight: bold;
    font-size: 20px;
    color: var(--form-green);
  }
}

@import url("https://fonts.googleapis.com/css2?family=IBM+Plex+Serif:wght@500;600;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@300;400;500;600;700;800&family=Source+Sans+3&display=swap");

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  // padding: 2rem;
}
* {
  // font-family: 'IBM Plex Sans', sans-serif;
  font-family: "Source Sans 3", sans-serif;
  line-height: 30px;
}
@media (min-width: 320px) and (max-width: 480px) {
  body {
    // padding: 30px 20px;
  }
}

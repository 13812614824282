.dashboardWrapper {
  // background-color: #FFF9F2;
  // min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  .createFormInputWrapper {
    // background-color: red;
    max-width: 400px;
    width: 100%;
    height: 40vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 2rem;
    .createHeader {
      color: var(--form-green);
      font-size: 30px;
      font-weight: 600;
    }
  }
  .bottomTabPanel {
    margin-left: 2rem;
    margin-right: 2rem;
    display: flex;
    flex-direction: column;
  }
  .tabTypography {
    text-transform: none;
    font-weight: bold;
    font-size: 20px;
    color: var(--form-green);
  }
}

.dashboardInputCreate {
  background-color: var(--white);
  border: 2px solid var(--form-orange);
  border-radius: 30px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-family: "Source Sans 3", sans-serif;
  // min-width: 400px;
  width: 100%;
  .dashboardCreateUI {
    font-family: "Source Sans 3", sans-serif;
    margin-left: 10px;
  }
  .dashboardCreateButton {
    font-family: "Source Sans 3", sans-serif;
    border-radius: 20px;
    margin: 5px;
    text-decoration: none;
    text-transform: none;
    // padding-left: 1rem;
    // padding-right: 1rem;
  }
}

.formCard {
  width: 10rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  min-height: 10rem;
  padding: 10px;
  position: relative;
  cursor: pointer;
  .cardTopBar {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .cardImg {
    max-width: 10rem;
    position: static;
    object-fit: cover;
  }
  .dateContainer {
    position: absolute;
    width: 100%;
    background-color: #fad0ab;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
    bottom: 0;
    min-height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    .dateFont {
      text-align: center;
      font-size: 14px;
    }
  }
}

// @media (max-width: 720px) {
//     .formCard{
//         // background-color: rebeccapurple;
//         // color: #0f38de;
//     }
// }

.createFormWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 1rem;
  height: 100%;
  padding: 0 2rem 2rem 2rem;
}
.createSectionContainer {
  width: 100%;
  overflow: hidden;
  .createSectionScroll {
    display: flex;
    flex-wrap: wrap;
  }
}

hr.solid {
  width: 100%;
  border: solid rgb(177, 177, 177) 1px;
}

.formImagePreview {
  border-radius: 4px;
  width: 100%;

  max-height: 30vh;
  object-fit: cover;
}

.sectionWrapper {
  border-left: 5px solid #4285f4;
  background-color: var(--white);
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 15px;
  border-radius: 8px;
  box-shadow:
    0px 3px 1px -2px rgba(0, 0, 0, 0.2),
    0px 2px 2px 0px rgba(0, 0, 0, 0.14),
    0px 1px 5px 0px rgba(0, 0, 0, 0.12);

  // padding: 1rem;
  .sectionDataContainer {
    width: 100%;
    text-align: center;
    display: flex;
    flex-direction: column;
    // justify-content: center;
    // align-items: center;
    gap: 1rem;
  }
}

.bannerWrapper {
  width: 100%;
  background-color: transparent;
  .sectionImg {
    min-height: 30vh;
    max-height: 30vh;
    width: 100%;
    object-fit: cover;
  }
  .sectionUploadMessage {
    background-color: #e6eef1;
    min-height: 30vh;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .optionsWrapper {
    background-color: rgb(255, 255, 255);
    right: 15px;
    top: 8px;
    cursor: pointer;
    border-radius: 2px;
    box-shadow: 1px 1px 8px rgb(198, 198, 198);
  }
  .titleWrapper {
    right: 15px;
    top: 8px;
  }
}
.formImageContainer {
  display: flex;
  justify-content: center;
}
.overlay-text {
  position: absolute;
  top: 15%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: white;
  font-size: 24px;
  font-weight: bold;
  text-align: center;
  background-color: rgba(0, 0, 0, 0.5);
  padding: 10px;
  border-radius: 5px;
}
.overlay-text-preview {
  position: absolute;
  top: 10%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: white;
  font-size: 24px;
  font-weight: bold;
  text-align: center;
  background-color: rgba(0, 0, 0, 0.5);
  padding: 10px;
  border-radius: 5px;
}
.componentContainer {
  width: 100%;
  max-width: 800px;
}

@media (max-width: 720px) {
  .formImagePreview {
    max-height: 20vh;
  }
}

@media (min-width: 768px) {
  .componentContainer {
    max-width: 800px;
  }
}

.settingContainer {
    display: grid;
    gap: 1rem;
    padding: 2rem;
}

.settingContainer>div {
    width: 100%;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    gap: 1rem;
}

@media screen and (min-width: 700px) {
    .settingContainer>div {
        width: 50%;
    }
}
.formPrevewWrapper {
  background-color: var(--formBackgroundBlue); //dynamic
  font-family: "Source Sans 3", sans-serif; // dynamic
  align-items: center;
  gap: 1rem;
  padding-bottom: 3rem;
  min-height: 100vh;
  width: 100%;
}
.previewTitle {
  font-size: 18px;
  font-weight: normal;
}

.previewBanner {
  width: 100%;
  height: fit-content;
  height: 20vh;
  .previerBannerImg {
    height: 100%;
    width: 100%;
    object-fit: cover;
  }
}

.previewSectionWrapper {
  padding: 5px 20px;
  margin: 0 20px;
  .previewHeader {
    font-family: "Source Sans 3", sans-serif;
    font-weight: bold;
    font-size: 24px;
    text-align: center;
  }
  .previewDescription {
    font-family: "Source Sans 3", sans-serif;
    font-size: 18px;
  }
}
.previewQuestion {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0.75rem;
}
.questionTitlePaper {
  width: 100%;
  max-width: 800px;
  display: flex;
  align-items: center;
  gap: 1rem;
  margin: 0 20px;
}
.questionTitleHighlight {
  border-left: 6px solid var(--form-orange);
  min-height: 50px;
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
}
.sectionTitlePaper {
  width: 100%;
  border-left: 6px solid var(--form-orange);
  max-width: 800px;
  display: flex;
  align-items: center;
  gap: 1rem;
  margin: 0 20px;
}
.sectionTitleHighlight {
  min-height: 50px;
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
}
.componentPreview {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 2rem;
  width: 100%;
}
@media (max-width: 720px) {
  .componentPreview {
    // padding: 0 20px;
  }
  .previewBanner {
    max-height: 15vh;
  }
}

.createHeaderWrapper {
  min-height: 100vh;
  font-family: "Inter", sans-serif;
  font-family: "Source Sans 3", sans-serif;
  .headerMain {
    background-color: var(--form-green);
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-inline: 2rem;
    width: 100%;
  }
}

.logoWrapper {
  display: flex;
  align-items: center;
  cursor: pointer;
  .logo_main {
    height: 3rem;
    padding: 0.5rem;
    width: 3rem;
  }
  .logo_typo {
    color: var(--white);
    cursor: pointer;
    font-weight: 600;
  }
}

@media (max-width: 480px) {
  .createHeaderWrapper {
    .headerMain {
      padding-inline: 10px;
    }
  }
  .logoWrapper {
    .logo_main {
      height: 2rem;
      width: 2rem;
    }
    .logo_typo {
      display: none;
    }
  }
}

:root {
  --white: #ffffff;

  --primary-white: #f7f7f7;
  --primary-black: #2d2d2d;
  --primary-yellow: #ffd370;
  --primary-background-color: #232323;
  --primary-link-color: #409fff;
  --bright-blue: #409fff;
  --error-red: #f7544c;
  --text-red: #df3030e6;
  --border-red: #df30309c;
  --modalBlack: #232323;
  --btnBlue: #166bda;
  --green: #2ee87f;
  --inputRed: #f7544c;
  --postlogin-primary-color: #166bda;
  --cardShadow: 0px 0px 20px #e9e9ef;

  --form-orange: #f48221;
  --form-green: #004b57;
  --formBackgroundBlue: #eef5fa;
  --greyBackground: #cecece;
}

.sectionButtonWrapper {
  display: flex;
  // gap: 5rem;
  align-items: center;
  margin-bottom: 1rem;
  .sectionButton {
    background-color: var(--white);
    border-radius: 8px;
    min-width: 6rem;
    max-width: 10rem;
    height: 4rem;
    border: none;
    box-shadow: 0px 0px 12px #bcbcbc;
    color: var(--primary-black);
  }
  .buttonBorder {
    border: 2px solid rgb(62, 191, 246);
    background-color: rgb(62, 191, 246);
    color: black;
  }
  .sectionConnector hr {
    width: 50px;
    max-width: 50px;
    border: solid rgb(62, 191, 246) 2px;
  }
}
.sectionReorderButtonWrapper {
  display: flex;
  align-items: center;
  margin-bottom: 1rem;
  gap: 1rem;
  .sectionReorderButton {
    background-color: var(--white);
    border-radius: 8px;
    width: 90%;
    min-height: 2rem;
    border: none;
    box-shadow: 0px 0px 12px #bcbcbc;
    color: var(--primary-black);
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10px;
  }
  .buttonBorder {
    border: 2px solid rgb(62, 191, 246);
    background-color: rgb(62, 191, 246);
    color: black;
  }
}

.createSectionWrapper {
  margin-right: 2rem;
  .createSectionButton {
    background-color: transparent;
    border-radius: 8px;
    min-width: 6rem;
    max-width: 10rem;
    height: 4rem;
    display: flex;
    flex-direction: column;
    font-size: 12px;
    color: var(--primary-black);
  }
  .createSectionButton:hover {
    background-color: #fff;
  }
}
.createReorderSectionWrapper {
  margin-right: 2rem;
  .createSectionButton {
    background-color: transparent;
    border-radius: 8px;
    min-width: 6rem;
    max-width: 10rem;
    height: 4rem;
    display: flex;
    flex-direction: column;
    font-size: 12px;
    color: var(--primary-black);
  }
  .createSectionButton:hover {
    background-color: #fff;
  }
}

.formActionComponents {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  align-items: center;
  .actionWrapper {
    // width: 55%;
    display: flex;
    justify-content: end;
    .actionItems {
      display: flex;
      align-items: center;
      gap: 0.5rem;
    }
  }
}

.createSectionTitlePaper {
  width: 100%;
  border-left: 5px solid var(--form-orange);
  display: flex;
  align-items: center;
  gap: 1rem;
  padding: 1rem;
}
// @media (min-width: 768px) {
//   .createSectionTitlePaper {
//     min-width: 700px;
//   }
// }

@media (max-width: 480px) {
  .formActionComponents {
    .actionWrapper {
      width: 100%;
    }
  }
  .createReorderSectionWrapper {
    margin-left: 3rem;
  }
}
